import { shuffleArray } from './utils/common.util';
import { objectKeys } from '@magicyard/doodledash-shared/utils';
import { ServerWord } from './Game';
import fetch from 'node-fetch';

export const updateWords = async (shownWordsThisRound: string[], guessedWordsThisRound: string[]) => {
  const API_URL = 'https://words-api.magicyard.workers.dev';
  const toSend = shownWordsThisRound.reduce<{ [key: string]: boolean }>((acc, w) => {
    acc[w] = guessedWordsThisRound.includes(w);
    return acc;
  }, {});
  console.log('sending to backend', toSend);
  try {
    await fetch(`${API_URL}/batch`, {
      method: 'POST',
      body: JSON.stringify({ attempts: toSend }),
    });
  } catch (e) {
    console.error('Failed to update words!');
  }
};

export const MAX_WORDS = 4;
export const getWordsForTopicForRound = (words: ServerWord[], alreadyUsedWords: string[]) => {
  const groupedWords = processWords(words);
  return objectKeys(groupedWords).map((k) => {
    const shuffled = shuffleArray(groupedWords[k]);
    const newWord = shuffled.find((s) => !alreadyUsedWords.includes(s.word));
    return { ...(newWord ?? shuffled[0]), score: +k };
  });
};
export type DifficultyGroups = 10 | 20 | 30 | 40;
const getDifficultyGroup = (difficulty: number): DifficultyGroups => {
  if (difficulty <= 3) {
    return 10;
  } else if (difficulty <= 6) {
    return 20;
  } else if (difficulty <= 9) {
    return 30;
  } else {
    return 40;
  }
};
const processWords = (
  words: {
    word: string;
    hint: string;
    difficulty: number;
  }[]
): { [key in DifficultyGroups]: Array<{ word: string; hint: string }> } => {
  const result: { [key in DifficultyGroups]: Array<{ word: string; hint: string }> } = {
    10: [],
    20: [],
    30: [],
    40: [],
  };
  words.map((w) => {
    result[getDifficultyGroup(w.difficulty)].push({ word: w.word, hint: w.hint });
  });
  return result;
};
