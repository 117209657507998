import React from 'react';
import './App.css';

export const AppBody = ({ title, children }: { title: string | undefined; children: React.ReactNode | undefined }) => {
  return (
    <>
      {/*<OrientationMessage />*/}
      {/*<ToggleFullScreenButton />*/}
      <div className={'app-body-container'}>
        <div className={'app-title'}>{title}</div>
        {children}
      </div>
    </>
  );
};
