import React from 'react';

const PageHeader = ({ title = '' }) => {
  return (
    <div className="text--subtitle text-center w-100">
      <div style={{ padding: '0 50px' }}>{title}</div>
    </div>
  );
};

export default PageHeader;
