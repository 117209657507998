import React, { useEffect, useState } from 'react';
import { useGameObject } from '../../store/GameContext';
import { Teams } from '@magicyard/doodledash-game/src/Types';
import { GameButton } from '../../components/game-button/GameButton';
import { track } from '@magicyard/shared/src/analytics';

export const PickTeam = () => {
  const { G, moves, playerID } = useGameObject();
  const [isDev, setIsDev] = useState(0);

  useEffect(() => track('Teams choice phase loaded'), []);
  const getTeamForPlayer = (): Teams => {
    if (G.teamsForPlayers.red.some((id) => id === playerID)) {
      return 'red';
    }
    return 'blue';
  };
  const canStartGame = (G.teamsForPlayers.red.length >= 2 && G.teamsForPlayers.blue.length >= 2) || isDev > 5;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ fontSize: '4vh', padding: '20px', borderRadius: '20px' }}>
        You are on team{' '}
        <span style={{ color: getTeamForPlayer() === 'red' ? '#eb6e57' : '#39c8c1' }}>{getTeamForPlayer()}</span>
      </div>
      <div
        style={{ width: '100%', height: 50, position: 'absolute', top: 0, zIndex: 1 }}
        onClick={() => setIsDev((x) => x + 1)}
      />
      <GameButton
        variant={getTeamForPlayer() === 'blue' ? 'red' : 'blue'}
        onClick={() => moves.toggleTeam()}
        style={{ marginTop: '30vh' }}
      >
        Switch to {getTeamForPlayer() === 'blue' ? 'red' : 'blue'}
      </GameButton>
      <GameButton
        style={{ position: 'absolute', bottom: '5vh' }}
        variant={!canStartGame ? 'red' : 'green'}
        onClick={() => moves.confirmTeams(isDev > 5)}
        disabled={!canStartGame}
      >
        {!canStartGame ? 'Min 2 per team' : 'Start game'}
      </GameButton>
    </div>
  );
};
