import React from 'react';
import { useGameObject } from '../../../../store/GameContext';

interface WordSuggestionsBoxProps {
  wordSelection: Array<{ item: { word: string; hint: string; score: number }; isDone: boolean }>;
  onSelect: (sWords: { word: string; hint: string; score: number }) => void;
}

export const WordSuggestionsBox = ({ onSelect, wordSelection }: WordSuggestionsBoxProps) => {
  const { G } = useGameObject();
  return (
    <div className={'round-setup-pick-word_floating-words'}>
      <div className={`round-setup-pick-word_title round-setup-pick-word_title-${G.activePlayer.team}`}>
        <div style={{ marginTop: '2vh' }}>Pick a word to draw</div>
      </div>
      <div className={'round-setup-pick-word_floating-words-content'}>
        <div className={'round-setup-pick-word_button-grid'}>
          {wordSelection.map((sWord, index) => {
            const isLong = sWord.item.word.length > 12;
            return sWord.isDone ? (
              <div key={sWord.item.word} className={'round-setup-pick-word_done-btn'}>
                <div className={'round-setup-pick-word_done-check'} />
                <div className={'round-setup-pick-word_floating-words-button-body'}>
                  <span className={isLong ? 'round-setup-pick-word_long-word' : undefined}>{sWord.item.word}</span>+
                  {sWord.item.score}
                </div>
              </div>
            ) : (
              <div
                key={sWord.item.word}
                onClick={() => onSelect(sWord.item)}
                className={`round-setup-pick-word_floating-words-button`}
              >
                <div className={'round-setup-pick-word_floating-words-button-body'}>
                  <span className={isLong ? 'round-setup-pick-word_long-word' : undefined}>{sWord.item.word}</span>+
                  {sWord.item.score}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
