import React, { useEffect } from 'react';
import { useGameObject } from '../../../store/GameContext';
import { Sketch } from '../../../components/Sketch/Sketch';
import PageWrapper from '../../../components/TemplateCommon/PageWrapper';
import './ActiveDrawingSimultaneous.css';
import { track } from '@magicyard/shared/src/analytics';
import { RoundSetupPickWord } from '../round-setup-pick-word/RoundSetupPickWord';
import { didGuessEverythingThisRound } from '@magicyard/doodledash-game/src/utils/moves.util';

export const DRAWING_STORAGE_KEY = 'drawingData';
export const getStorageKeyForPlayer = (playerID: string) => {
  return DRAWING_STORAGE_KEY + playerID;
};

export const clearActiveDrawingStorage = (playerID: string) => {
  sessionStorage.removeItem(getStorageKeyForPlayer(playerID));
};

export const ActiveDrawingSimultaneous = () => {
  const { G, playerID } = useGameObject();
  useEffect(() => {
    return () => {
      track(`Round Ended ${didGuessEverythingThisRound(G) ? 'All Words Guessed' : 'Times Up'}`);
    };
  }, []);
  if (G.currentDrawingWord === null) {
    clearActiveDrawingStorage(playerID);
    return <RoundSetupPickWord />;
  }
  return <XXX word={G.currentDrawingWord} />;
};

const XXX = ({ word }: { word: { word: string; hint: string } }) => {
  const { moves, G, playerID } = useGameObject();
  useEffect(() => {
    return () => {
      localStorage.removeItem(getStorageKeyForPlayer(playerID));
    };
  }, []);

  return (
    <>
      {/*<ControllerTransitionOverlay show={isTransition} image={LoaderStageThree} />*/}
      <PageWrapper>
        <div className={'d-flex fill-height w-100 mb-5'}>
          <div
            style={{ alignItems: 'center' }}
            className={'flex d-flex flex-column fill-height position-relative w-100'}
          >
            <div
              style={{
                textAlign: 'center',
                fontSize: 32,
                position: 'absolute',
                top: -58,
              }}
            >
              {word.word}
            </div>
            <div className={'active-drawing-simultaneous_content-container'}>
              <Sketch moves={moves} storageKey={getStorageKeyForPlayer(playerID)} />
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
