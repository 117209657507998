import { ActivePlayersArg, Ctx, PlayerID } from 'boardgame.io';
import { G, ServerWord } from './Game';
import { RandomAPI } from 'boardgame.io/dist/types/src/plugins/random/random';
import { EventsAPI } from 'boardgame.io/dist/types/src/plugins/events/events';
import MovesUtil from './utils/moves.util';

export interface EventsAPIGuaranteed extends EventsAPI {
  endGame(gameover?: any): void;

  endPhase(): void;

  endStage(): void;

  endTurn(arg?: { next: PlayerID }): void;

  pass(arg?: { remove: true }): void;

  setActivePlayers(arg: ActivePlayersArg): void;

  setPhase(newPhase: string): void;

  setStage(newStage: string): void;
}

export interface CtxWithApi extends Ctx {
  events: EventsAPIGuaranteed;
  random: RandomAPI;
  playerID: PlayerID; // Assuming playerId is always present maybe wrong
}

export interface Point {
  x: number;
  y: number;
}

export type Drawing = {
  playerID: string;
  word: { word: string; hint: string };
};

export type BoardPropTypes = {
  ctx: CtxWithApi;
  events: unknown;
  moves: { [key in keyof typeof MovesUtil]: (...arg: any) => void };
  playerID: PlayerID;
} & G;

export interface IPlayer {
  id: PlayerID;
  name: string;
  avatarUrl: string;
}

export interface Palette {
  name: string;
  colors: string[];
  light: string;
  dark: string;
}

export interface GameState {
  isSmall: boolean;
  smallMode: {
    activePlayerIndex: number;
    bonusRoundCount: number;
  };
  drawingSocketUrl: string;
  didGameEnd: boolean;
  isTransition: boolean;
  phaseStartTime: number;
  players: IPlayer[];
  shouldForceSubmit: boolean;
  activePlayer: ActivePlayer;
  currentDrawingWord: { hint: string; word: string; score: number } | null;
  teamsForPlayers: { [team in Teams]: PlayerID[] };

  wordSelectionForRound: Array<{ hint: string; word: string; score: number }>;
  successfulGuessesForRound: Array<{ guesses: Array<{ word: string; score: number }>; id: number | PlayerID }>;
  refreshCountThisRound: number;
  allWords: Array<ServerWord>;
}

type ActivePlayer = { red: number; blue: number; team: 'red' | 'blue' };

export const getActivePlayerId = (G: GameState) => {
  if (!G.isSmall) {
    return G.teamsForPlayers[G.activePlayer.team][G.activePlayer[G.activePlayer.team]];
  } else {
    return '' + G.smallMode.activePlayerIndex;
  }
};

export type Teams = 'red' | 'blue';

export interface Palette {
  name: string;
  colors: Array<string>;
  light: string;
  dark: string;
}
