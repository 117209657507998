import React from 'react';
import styles from '../../GameManager/Game.module.css';
import PageHeader from './PageHeader';

interface PageWrapperProps {
  children: React.ReactNode;
  classes?: string | string[];
  title?: string;
}

const PageWrapper = ({ children, classes = '', title }: PageWrapperProps) => {
  return (
    <div className={styles.commonWrapper}>
      <PageHeader title={title} />
      {children}
    </div>
  );
};

export default PageWrapper;
