import { INVALID_MOVE } from 'boardgame.io/core';
import {
  calcAmountOfRounds,
  getActiveGuessesAndPlayer,
  Phases,
  POINTS_LOST_PER_SHUFFLE,
} from '@magicyard/doodledash-game/src/Game';
import { CtxWithApi, GameState, getActivePlayerId } from '../Types';
import { assertNever } from './typeUtils';
import { getWordsForTopicForRound, MAX_WORDS, updateWords } from '../words';

const endSync = (G: GameState, ctx: CtxWithApi) => {
  console.log('ENDING SYNC PHASE');
  console.log(ctx.playerID);
  // Temp, the screen has the same playerId as the first player
  if (ctx.playerID === '0') {
    ctx.events.endPhase();
    return undefined;
  }
  return INVALID_MOVE;
};

const refreshWords = {
  move: (G: GameState, ctx: CtxWithApi) => {
    console.log('REFRESH');
    G.refreshCountThisRound++;
    const newWords = getWordsForTopicForRound(
      G.allWords,
      G.successfulGuessesForRound
        .reduce<string[]>((acc, successful) => acc.concat(successful.guesses.map((guess) => guess.word)), [])
        .concat(G.wordSelectionForRound.map((x) => x.word))
    );
    G.wordSelectionForRound = G.wordSelectionForRound.map((s, i) => {
      if (
        !G.successfulGuessesForRound[G.successfulGuessesForRound.length - 1].guesses.some((g) => s.word === g.word) &&
        G.wordSelectionForRound[i].score > POINTS_LOST_PER_SHUFFLE
      ) {
        return { ...newWords[i], score: newWords[i].score - POINTS_LOST_PER_SHUFFLE * G.refreshCountThisRound };
      }
      return s;
    });
  },
  client: false,
};

export const getAmountOfWordsThatWillBeRefreshed = (G: GameState) => {
  return G.wordSelectionForRound.reduce((acc, s, i) => {
    if (
      !G.successfulGuessesForRound[G.successfulGuessesForRound.length - 1].guesses.some((g) => s.word === g.word) &&
      G.wordSelectionForRound[i].score > POINTS_LOST_PER_SHUFFLE
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

const s00_confirmCycleCardWord = (
  G: GameState,
  ctx: CtxWithApi,
  selection: {
    word: string;
    hint: string;
    score: number;
  }
): void => {
  G.currentDrawingWord = selection;
};

const forceEndPhase = (G: GameState, ctx: CtxWithApi) => {
  console.log(`Phase ${ctx.phase} was forced to end`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  ctx.events.endPhase();
  return undefined;
};

const isMoveMadeByMasterClient = (ctx: CtxWithApi) => +ctx.playerID === ctx.numPlayers - 1;

export const didGuessEverythingThisRound = (G: GameState) => {
  const successfulGuesses = getActiveGuessesAndPlayer(G).guesses.length;
  return successfulGuesses % MAX_WORDS === 0 && successfulGuesses > G.smallMode.bonusRoundCount * MAX_WORDS;
};

const timesUp = {
  move: (G: GameState, ctx: CtxWithApi) => {
    console.log(`Player ${ctx.playerID} called timesUp on ${ctx.phase}`);

    if (!isMoveMadeByMasterClient(ctx)) {
      return INVALID_MOVE;
    }
    const phase = ctx.phase as Phases;
    switch (phase) {
      case Phases.Scoring:
        endScoring(G, ctx);
        break;
      case Phases.Playing:
      case Phases.Sync:
        ctx.events.endPhase();
        break;
      case Phases.PickTeam: {
        // No timer..
        break;
      }
      case Phases.GameEnd:
        // Last phase, do nothing
        break;
      default:
        assertNever(phase);
    }

    return undefined;
  },
  ignoreStaleStateID: true,
};

const endScoring = (G: GameState, ctx: CtxWithApi) => {
  // Update the server with the results
  updateWords(
    G.wordSelectionForRound.map((w) => w.word),
    getActiveGuessesAndPlayer(G).guesses.map((g) => g.word)
  );
  G.refreshCountThisRound = 0;
  if (!G.isSmall) {
    prepareNextTeam(G);
  } else {
    if (didGuessEverythingThisRound(G)) {
      G.smallMode.bonusRoundCount++;
    } else {
      prepareNextPlayer(G);
    }
  }
  G.wordSelectionForRound = getWordsForTopicForRound(
    G.allWords,
    G.successfulGuessesForRound.reduce<string[]>(
      (acc, successful) => acc.concat(successful.guesses.map((guess) => guess.word)),
      []
    )
  );
  ctx.events.endPhase();
};

const finishScoringEarlyIfNeeded = (G: GameState, ctx: CtxWithApi) => {
  if (
    G.successfulGuessesForRound.length - 1 === calcAmountOfRounds(G) - 1 &&
    G.isSmall &&
    !didGuessEverythingThisRound(G)
  ) {
    endScoring(G, ctx);
  }
};

const transitionTimeUp = (G: GameState, ctx: CtxWithApi) => {
  console.log(`Player ${ctx.playerID} ended the transiton for ${ctx.phase}`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  G.isTransition = false;
  return undefined;
};

const toggleTeam = {
  move: (G: GameState, ctx: CtxWithApi) => {
    const { playerID } = ctx;
    const redIndex = G.teamsForPlayers.red.indexOf(playerID);
    if (redIndex !== -1) {
      G.teamsForPlayers.red.splice(redIndex, 1);
      G.teamsForPlayers.blue.push(playerID);
    } else {
      const blueIndex = G.teamsForPlayers.blue.indexOf(playerID);
      G.teamsForPlayers.blue.splice(blueIndex, 1);
      G.teamsForPlayers.red.push(playerID);
    }
  },
  ignoreStaleStateID: true,
};

const finishDrawing = (G: GameState, ctx: CtxWithApi, success: boolean) => {
  if (success) {
    if (G.currentDrawingWord === null) {
      console.error('impossible to have a successful drawing without a word');
      return INVALID_MOVE;
    }
    getActiveGuessesAndPlayer(G).guesses.push(G.currentDrawingWord);
  }
  G.currentDrawingWord = null;

  if (success && didGuessEverythingThisRound(G)) {
    ctx.events.endPhase();
  }
  return undefined;
};
const prepareNextTeam = (G: GameState) => {
  const lastTeam = G.activePlayer.team;
  const nextTeam = lastTeam === 'red' ? 'blue' : 'red';
  const blueL = G.teamsForPlayers.blue.length;
  const redL = G.teamsForPlayers.red.length;
  const nextRed = lastTeam === 'red' ? G.activePlayer.red + 1 : G.activePlayer.red;
  const nextBlue = lastTeam === 'blue' ? G.activePlayer.blue + 1 : G.activePlayer.blue;

  G.activePlayer = {
    team: nextTeam,
    red: nextRed % redL,
    blue: nextBlue % blueL,
  };
  G.successfulGuessesForRound.push({ guesses: [], id: getActivePlayerId(G) });
};

const prepareNextPlayer = (G: GameState) => {
  G.smallMode.bonusRoundCount = 0;
  G.successfulGuessesForRound.push({ guesses: [], id: getActivePlayerId(G) });
  G.smallMode.activePlayerIndex = (G.successfulGuessesForRound.length - 1) % G.players.length;
};

const MovesUtil = {
  endSync,
  s00_confirmCycleCardWord,
  finishDrawing,
  toggleTeam,
  timesUp,
  forceEndPhase,
  transitionTimeUp,
  finishScoringEarlyIfNeeded,
  refreshWords,
};

export default MovesUtil;
export const calcScore = (G: GameState): { red: number; blue: number } => {
  return G.successfulGuessesForRound.reduce(
    (acc, guessesWithPlayerId, round) => {
      acc[G.teamsForPlayers.red.includes('' + guessesWithPlayerId.id) ? 'red' : 'blue'] +=
        guessesWithPlayerId.guesses.reduce((acc, g) => acc + g.score, 0);
      return acc;
    },
    { red: 0, blue: 0 }
  );
};
