import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Client } from 'boardgame.io/react';
import { DoodleDash, GameObject } from '@magicyard/doodledash-game/src/Game';
import reportWebVitals from './reportWebVitals';
import { App } from './App';
import { CONFIG, getMultiplayerMode } from '@magicyard/utils';
import Board from './components/Board';
import { identify, initAnalytics } from '@magicyard/shared/src/analytics';
import { initSentry } from '@magicyard/shared/src/sentry';
import { LoaderTemplate } from './components/loader-template/LoaderTemplate';
import { AppBody } from './AppBody';

initSentry();

console.log(
  `Starting with config: 
      REACT_APP_PLATFORM_SERVER_URL=${process.env.REACT_APP_PLATFORM_SERVER_URL}
      REACT_APP_PLATFORM_CONTROLLER_URL=${process.env.REACT_APP_PLATFORM_CONTROLLER_URL}
      REACT_APP_PLATFORM_DISPLAY_URL=${process.env.REACT_APP_PLATFORM_DISPLAY_URL}

      REACT_APP_GAME_START_SERVER_URL=${process.env.REACT_APP_GAME_START_SERVER_URL}
      REACT_APP_GAME_START_CONTROLLER_URL=${process.env.REACT_APP_GAME_START_CONTROLLER_URL}
      REACT_APP_GAME_START_DISPLAY_URL=${process.env.REACT_APP_GAME_START_DISPLAY_URL}
  `
);

console.log({ CONFIG });

const getDemoData = (): { playerID: string; matchID: string; controllerId: string | null } | null => {
  const urlParams = new URLSearchParams(window.location.search);
  const playerID = urlParams.get('playerID');
  const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID;
  const controllerID = urlParams.get('controllerID');

  if (playerID === null || matchID === null || matchID === undefined) {
    return null;
  }

  return { playerID: playerID, matchID: matchID, controllerId: controllerID };
};

const demoData = getDemoData();
if (demoData !== null) {
  initAnalytics(CONFIG.MIXPANEL_API_KEY, 'Controller', 'Platform', 'doodledash');
  const BgioClient = Client({
    loading: () => (
      <AppBody title={''}>
        <LoaderTemplate title={'Loading...'} />
      </AppBody>
    ),
    //@ts-ignore TODO: Remove this and fix resulting type error
    game: DoodleDash,
    board: (G) => {
      if (demoData.controllerId) {
        identify(demoData.controllerId);
      }
      return (
        <Board
          G={G as GameObject}
          onStartNewGame={() => {
            window.parent.postMessage('windowPostMessagePlayAgain', '*');
          }}
          onTryDifferentGames={() => {
            window.parent.postMessage('windowPostMessageTryDifferentGames', '*');
          }}
        />
      );
    },
    debug: false,
    multiplayer: getMultiplayerMode(),
  });
  ReactDOM.render(
    <React.StrictMode>
      <BgioClient playerID={demoData.playerID} matchID={demoData.matchID} />
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
