import React, { useState } from 'react';
import { DrawingBoard } from './drawing-board/DrawingBoard';
import { Toolbar } from './Toolbar';
import MovesUtil from '@magicyard/doodledash-game/src/utils/moves.util';
import './sketch.css';
import { SingleCanvas } from '../single-canvas/SingleCanvas';
import { useGameObject } from '../../store/GameContext';
import { track } from '@magicyard/shared/src/analytics';
import { clearActiveDrawingStorage } from '../../GameManager/Stages/active-drawing-simultaneous/ActiveDrawingSimultaneous';
import { GameButton } from '../game-button/GameButton';

export type SketchProps = {
  moves: { [key in keyof typeof MovesUtil]: (...args: any) => void };
  colorPalettes?: string[];
  storageKey: string;
};

export const Sketch = (props: SketchProps) => {
  const { playerID, matchID } = useGameObject();
  const { moves, colorPalettes = ['green', 'orange', 'purple'] } = props;
  const [currentColor, setCurrentColor] = useState(colorPalettes[0]);
  const [currentWidth, setCurrentWidth] = useState(5.2);
  const [mode, setMode] = useState<'draw' | 'erase'>('draw');

  const handleFinishDrawing = () => {
    track('Word guessed');
    clearActiveDrawingStorage(playerID);
    moves.finishDrawing(true);
  };

  const handleChangeDrawing = () => {
    clearActiveDrawingStorage(playerID);
    moves.finishDrawing(false);
  };
  const handleUndo = () => {
    setMode((m) => (m === 'draw' ? 'erase' : 'draw'));
  };
  return (
    <div className={'sketch_root'}>
      <div className={'sketch_drawing-single-canvas-container'}>
        <SingleCanvas playerID={playerID}>
          <DrawingBoard
            playerId={playerID}
            mode={mode}
            yardId={matchID}
            moves={moves}
            currentColor={currentColor}
            currentWidth={currentWidth}
            storageKey={props.storageKey}
            onTouchEnd={() => {
              if (mode === 'erase') {
                setMode('draw');
              }
            }}
          />
          <Toolbar
            color={currentColor}
            width={currentWidth}
            handleColor={(currColor: string) => setCurrentColor(currColor)}
            handleWidth={(currWidth: number) => setCurrentWidth(currWidth)}
            handleUndo={handleUndo}
            isErasing={mode === 'erase'}
          />
        </SingleCanvas>
      </div>
      <div className={'sketch_btn-container'}>
        <GameButton size={'sm'} variant={'red'} onClick={handleChangeDrawing}>
          Change word
        </GameButton>
        <GameButton size={'sm'} variant={'green'} onClick={handleFinishDrawing}>
          They guessed
        </GameButton>
      </div>
    </div>
  );
};
