import React, { useState } from 'react';
import './Toolbar.css';
import BrushIcon from '../../assets/doodleDash/brushIcon.webp';
import BackArrowIcon from '../../assets/doodleDash/eraser.webp';
import { ColorPicker } from './ColorPicker';
import { BrushesPopover } from './brushes-popover/BrushesPopover';
import { track } from '@magicyard/shared/src/analytics';

interface ColorButtonItemProps {
  isActive: boolean;
  color: string;
  onClick: () => void;
  animationIndex?: number;
}

export const ColorButtonItem = (props: ColorButtonItemProps) => {
  return (
    <div
      className={`
        toolbar_color-button
        ${props.animationIndex !== undefined && props.isActive ? ' toolbar_color-button-active-enter' : ''},
        ${props.animationIndex !== undefined && !props.isActive ? ' toolbar_color-button-enter' : ''}
      `}
      style={{
        backgroundColor: props.color,
        animationDelay: props.animationIndex === undefined ? '' : props.animationIndex * 0.025 + 's',
        border: props.animationIndex === undefined && props.isActive ? '2px solid black' : '',
      }}
      onClick={props.onClick}
    />
  );
};

export interface ToolbarProps {
  color: string;
  width: number;
  handleColor: (currColor: string) => void;
  handleWidth: (currWidth: number) => void;
  handleUndo: () => void;
  isErasing: boolean;
}

export const Toolbar = ({ handleUndo, handleColor, handleWidth, color, isErasing }: ToolbarProps) => {
  const handleBrushWidthSelect = (size: number | undefined) => {
    handleBrushPickerClick();
    if (size) {
      handleWidth(size);
      track('Drawing Brush Width Selected', { width: size });
    }
  };

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isBrushPickerOpen, setIsBrushPicker] = useState(false);

  const handleColorPickerClick = (pColor: string) => {
    if (isBrushPickerOpen) {
      setIsBrushPicker(false);
    }
    if (isColorPickerOpen) {
      handleColor(pColor);
      setIsColorPickerOpen(false);
      return;
    }
    track('Drawing Color Selected', { color: pColor });
    setIsColorPickerOpen(true);
  };
  const handleBrushPickerClick = () => {
    if (isColorPickerOpen) {
      setIsColorPickerOpen(false);
    }
    if (isBrushPickerOpen) {
      setIsBrushPicker(false);
      return;
    }

    setIsBrushPicker(true);
  };

  return (
    <>
      {isColorPickerOpen || isBrushPickerOpen ? (
        <div
          className={'brush-popover_container'}
          onTouchStart={() => {
            setIsBrushPicker(false);
            setIsColorPickerOpen(false);
          }}
        />
      ) : undefined}
      <div className={'Toolbar__wrapper'}>
        <div className={'Toolbar__innerBox'}>
          <div className={'toolbar_buttons-container'}>
            <div className={'Toolbar__actionButton'}>
              <div className={'toolbar_popover-container-color'}>
                {isColorPickerOpen ? (
                  <ColorPicker
                    activeColor={color}
                    onClick={(pColor) => handleColorPickerClick(pColor)}
                    isOpen={isColorPickerOpen}
                  />
                ) : undefined}
              </div>
              <div>
                <ColorButtonItem
                  key={`${color}-active-color`}
                  isActive={true}
                  color={color}
                  onClick={() => handleColorPickerClick(color)}
                />
              </div>
            </div>

            <div className={'Toolbar__actionButton'}>
              <div style={{ display: 'flex' }}>
                <div className={'toolbar_popover-container-brush'}>
                  {isBrushPickerOpen ? (
                    <BrushesPopover color={color} onClick={(size) => handleBrushWidthSelect(size)} />
                  ) : undefined}
                </div>
                <img width={'40vw'} src={BrushIcon} alt="" onClick={() => handleBrushPickerClick()} />
              </div>
            </div>
            <div
              className={`Toolbar__actionButton ${isErasing ? 'Toolbar__eraser-selected' : ''}`}
              onClick={handleUndo}
            >
              <img width={'50vw'} src={BackArrowIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
