import React, { useEffect, useState } from 'react';
import { track } from '@magicyard/shared/src/analytics';
import { getNumberOfPlayAgain } from '../App';
import { GameButton } from '../components/game-button/GameButton';
import './WinnerScene.css';
import { getServerTimePassedMs, useMillisReachedFromServer } from '@magicyard/doodledash-shared/hooks/IntervalHooks';
import { Phases } from '@magicyard/doodledash-game/src/Game';
import { useGameObject } from '../store/GameContext';
import { calcScore } from '@magicyard/doodledash-game/src/utils/moves.util';

const DISPLAY_RATE_US_AFTER = 2;
const DID_DISPLAY_POPUP_KEY = 'd_d_p_k';
const getDidDisplayPopup = () => {
  return localStorage.getItem(DID_DISPLAY_POPUP_KEY) === 'true';
};

const setDisplayedPopup = () => {
  return localStorage.setItem(DID_DISPLAY_POPUP_KEY, 'true');
};
const SHOULD_SHOW_TRY_KEY = '$$showtry$$';

const getShouldShowTryMoreGames = () => {
  const item = localStorage.getItem(SHOULD_SHOW_TRY_KEY);
  return item !== null && +item > 3;
};

const incShouldShowTryMoreGames = () => {
  const item = localStorage.getItem(SHOULD_SHOW_TRY_KEY);
  localStorage.setItem(SHOULD_SHOW_TRY_KEY, item === null ? '0' : '' + (+item + 1));
};

export const WinnerScene = ({
  onStartNewGame,
  onTryDifferentGames,
}: {
  onStartNewGame: () => void;
  onTryDifferentGames?: () => void;
}) => {
  const { G, ctx } = useGameObject();
  const [showPlayAgain, setShowPlayAgain] = useState(false);

  useEffect(() => incShouldShowTryMoreGames(), []);

  useMillisReachedFromServer({
    serverTimePassedMs: getServerTimePassedMs(G.phaseStartTime, ctx.phase as Phases, false),
    millisWithCallback: {
      1000: () => {
        const score = calcScore(G);
        if (score.red === score.blue) {
          setShowPlayAgain(true);
        }
      },
      3000: () => {
        if (G.players.length > 3) {
          setShowPlayAgain(true);
        }
      },
      7000: () => {
        setShowPlayAgain(true);
      },
    },
  });

  useEffect(() => {
    track('Winner Phase Started');
  }, []);

  const n = getNumberOfPlayAgain();

  const [show, setShow] = useState(n + 1 === DISPLAY_RATE_US_AFTER && !getDidDisplayPopup());
  useEffect(() => {
    setDisplayedPopup();
  });

  return (
    <div className={'loader-template_root'}>
      {/*<PopupContainer show={show}>*/}
      {/*  <div>*/}
      {/*    <div className={'winner_scene-title'}>Enjoyed playing?</div>*/}
      {/*    <div className={'winner_scene-subtitle'}>Please rate us (:</div>*/}
      {/*  </div>*/}
      {/*  <div className={'winner_scene-stars'} />*/}
      {/*  <div className={'winner_scene-btns'}>*/}
      {/*    <GameButton*/}
      {/*      variant={'green'}*/}
      {/*      onClick={() => {*/}
      {/*        window.open(*/}
      {/*          'https://www.amazon.com/review/create-review/edit?ie=UTF8&channel=glance-detail&asin=B0C1F6NQTG',*/}
      {/*          '_blank'*/}
      {/*        );*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Rate us!*/}
      {/*    </GameButton>*/}
      {/*    <GameButton variant={'red'} onClick={() => setShow(false)} size={'sm'}>*/}
      {/*      Maybe later..*/}
      {/*    </GameButton>*/}
      {/*  </div>*/}
      {/*</PopupContainer>*/}
      {showPlayAgain ? (
        <>
          <GameButton variant={'green'} onClick={onStartNewGame}>
            Play again!
          </GameButton>
          {onTryDifferentGames !== undefined && getShouldShowTryMoreGames() && (
            <GameButton variant={'purple'} onClick={onTryDifferentGames}>
              Try more games!
            </GameButton>
          )}
        </>
      ) : (
        <div></div>
        // <LoaderTemplate />
      )}
    </div>
  );
};
