import React from 'react';
import { useGameObject } from '../../../store/GameContext';
import PageWrapper from '../../../components/TemplateCommon/PageWrapper';
import { WordSuggestionsBox } from './components/WordSuggestionsBox';
import './RoundSetupPickWord.css';
import { LoaderTemplate } from '../../../components/loader-template/LoaderTemplate';
import { track } from '@magicyard/shared/src/analytics';
import { getActiveGuessesAndPlayer, POINTS_LOST_PER_SHUFFLE } from '@magicyard/doodledash-game/src/Game';
import { GameButton } from '../../../components/game-button/GameButton';
import { getAmountOfWordsThatWillBeRefreshed } from '@magicyard/doodledash-game/src/utils/moves.util';

export const RoundSetupPickWord = () => {
  const { G, moves } = useGameObject();
  const isTransitionOverlay = G.isTransition;
  const handleSuggestionBoxSelect = (sItem: { word: string; hint: string; score: number }) => {
    track('Word Chosen', { word: sItem.word });
    moves.s00_confirmCycleCardWord(sItem);
  };

  if (isTransitionOverlay) {
    return <LoaderTemplate />;
  }

  return (
    <PageWrapper>
      <div className={'pick-word_root'}>
        <WordSuggestionsBox
          onSelect={handleSuggestionBoxSelect}
          wordSelection={G.wordSelectionForRound.map((w) => {
            return {
              isDone: getActiveGuessesAndPlayer(G).guesses.some((x) => x.word === w.word),
              item: w,
            };
          })}
        />
        <GameButton
          variant={'purple'}
          onClick={() => moves.refreshWords()}
          disabled={getAmountOfWordsThatWillBeRefreshed(G) === 0}
        >
          Refresh words
        </GameButton>
      </div>
    </PageWrapper>
  );
};
