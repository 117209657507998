import { useCallback, useEffect, useRef, useState } from 'react';
import { transitionTimeForPhasesInMs } from '@magicyard/doodledash-game/src/Config';
import { Phases } from '@magicyard/doodledash-game/src/Game';
// @ts-expect-error
import * as timesync from 'timesync';
import { GAME_START_SERVER_URL } from '@magicyard/utils';
import { assertIsDefined, assertNever } from '@magicyard/doodledash-game/src/utils/typeUtils';
import { objectKeys } from '../utils';
import { useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';

export const BACKGROUND_TRANSITION_MS = 1500;

// TODO fix this
// assertIsDefined(GAME_START_SERVER_URL, 'Game start server required.');
export const ts: { now: () => number; sync: () => void } = timesync.create({
  server: `${GAME_START_SERVER_URL}/timesync`,
});

export const getTimePassedBasedOnServer = (phaseStartTime: number) => {
  return ts.now() - phaseStartTime;
};

/**
 * Calculates the time passed from server once
 * @param phaseStartTime
 * @param phase
 * @param isTransition
 */
export const getServerTimePassedMs = (phaseStartTime: number, phase: Phases, isTransition: boolean) => {
  const extraTime = (isTransition ? 0 : transitionTimeForPhasesInMs[phase] ?? 0) + BACKGROUND_TRANSITION_MS;
  const timePassed = getTimePassedBasedOnServer(phaseStartTime);

  return timePassed - extraTime;
};

export const useServerAudio = ({
  phaseStartTime,
  phase,
  isTransition,
  audio,
}: {
  phaseStartTime: number;
  phase: Phases;
  isTransition: boolean;
  audio: HTMLAudioElement;
}) => {
  const timeoutId = useRef<number | undefined>(undefined);

  const onBlur = useCallback(() => {
    window.clearTimeout(timeoutId.current);
    audio.pause();
  }, [audio]);

  const onFocus = useCallback(() => {
    const serverTime = getServerTimePassedMs(phaseStartTime, phase, isTransition);
    if (serverTime < 0) {
      timeoutId.current = window.setTimeout(() => {
        void audio.play();
      }, Math.abs(serverTime));
    } else {
      audio.currentTime = serverTime / 1000;
      void audio.play();
    }
  }, [audio]);

  useNativeFocus(onFocus, onBlur);
};

const millisWithCallbackToArray = (msWithCb: Record<number, () => void>) =>
  objectKeys(msWithCb)
    .sort((a, b) => a - b)
    .map((key) => {
      return {
        millis: +key,
        callback: msWithCb[key],
      };
    });
/**
 * Will call all functions in order of millis.
 * @param serverTimePassedMs
 * @param millisWithCallback Will be called in order of millis after enough server time had passed
 */
export const useMillisReachedFromServer = ({
  serverTimePassedMs,
  millisWithCallback,
}: {
  serverTimePassedMs: number;
  millisWithCallback: Record<number, () => void>;
}) => {
  const [sortedMillisWithCallback] = useState(millisWithCallbackToArray(millisWithCallback));

  useEffect(() => {
    const uncalledActions = sortedMillisWithCallback.filter((action) => {
      if (serverTimePassedMs >= action.millis) {
        action.callback();
        return false;
      }

      return true;
    });
    const ids = uncalledActions.map((action) => window.setTimeout(action.callback, action.millis - serverTimePassedMs));
    return () => ids.forEach(window.clearTimeout);
  }, [sortedMillisWithCallback]);
};
