import React from 'react';
import './Background.css';

export type BackgroundProps = {
  className?: string;
  children?: React.ReactNode;
  image?: any;
};

export const Background = ({ children }: BackgroundProps) => {
  return <div className={'background_root'}>{children}</div>;
};

export default Background;
