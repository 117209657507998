import React, { HTMLInputTypeAttribute, useEffect } from 'react';

import './SubmittableInput.css';
import { GameButton } from '../game-button/GameButton';

interface BrushInputProps {
  value?: string;
  defaultValue?: string;
  isValid?: boolean;
  onChange?: (value: string) => void;
  onSubmit: (value: string) => void;
  style?: React.CSSProperties;
  forceEnglish: boolean;
  type?: HTMLInputTypeAttribute;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  placeholder?: string;
  tabIndex?: number;
  autoFocus?: boolean;
  pattern?: string;
}

export const SubmittableInput = ({
  value,
  defaultValue,
  style,
  onSubmit,
  onChange,
  isValid,
  forceEnglish,
  type = 'text',
  inputMode,
  placeholder,
  tabIndex,
  autoFocus,
  pattern,
}: BrushInputProps) => {
  const [msg, setMsg] = React.useState('');
  const [msgTimeout, setMsgTimeout] = React.useState<number | undefined>(undefined);
  const [text, setText] = React.useState(value ?? defaultValue ?? '');

  useEffect(() => {
    return () => {
      window.clearTimeout(msgTimeout);
    };
  }, [msgTimeout]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = forceEnglish ? event.target.value.replace(/[^a-zA-Z ]/g, '') : event.target.value;
    if (event.target.value.length !== newVal.length) {
      setMsg('Only english letters are allowed');
      window.clearTimeout(msgTimeout);
      setMsgTimeout(
        window.setTimeout(() => {
          setMsg('');
        }, 1500)
      );
    } else {
      setMsg('');
      if (onChange !== undefined) {
        onChange(newVal);
      }
      setText(newVal);
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit(text);
    }
  };

  return (
    <div className={'submittable-input_root'}>
      <input
        autoFocus={true}
        value={text}
        onChange={handleChange}
        className={'submittable-input_input'}
        type={type}
        inputMode={inputMode}
        onKeyUp={handleKeyPress}
        placeholder={placeholder}
        pattern={pattern}
      />
      <div className={'submittable-input_submit'} onClick={() => onSubmit(text)} />
    </div>
  );
};
