import { Phases } from './Game';

const ms = 1000;

export const _DEMO_TEMP_INITIAL_DELAY = 12 * ms;

export const SYNC_MS = 10 * ms;
export const CHOOSE_WORD_TIMEOUT_MS = 70 * ms;
export const DRAWING_TIMEOUT_MS = 70 * ms;
export const GUESSING_TIMEOUT_MS = 70 * ms;
export const VOTING_TIMEOUT_MS = 25 * ms;
export const TRANSITION_TIMEOUT_MS = 10 * ms;
export const HOW_TO_WIN_MS = 23 * ms;
export const HOW_TO_WIN_TRANSITION_MS = 26 * ms; // + _DEMO_TEMP_INITIAL_DELAY
export const SCORE_MS = 17 * ms;
export const WINNER_MS = 10 * ms;
export const GAME_END = 60 * ms;

export const MAX_ROUNDS = 3;

export const waitTimeForPhaseInMs: Record<Phases, number> = {
  Sync: SYNC_MS,
  PickTeam: CHOOSE_WORD_TIMEOUT_MS * 1000,
  Playing: 75 * ms,
  Scoring: 8 * ms,
  gameEnd: GAME_END * 10000,
};

export const transitionTimeForPhasesInMs: Record<Phases, number | null> = {
  Sync: null,
  PickTeam: null,
  Playing: 5 * ms,
  Scoring: 5 * ms,
  gameEnd: null,
};
