import React, { CSSProperties } from 'react';
import './GameButton.css';

export interface GameButtonProps {
  disabled?: boolean;
  variant: 'red' | 'green' | 'purple' | 'blue';
  size?: 'sm';
  children: React.ReactNode;
  onClick: () => void;
  style?: CSSProperties;
  className?: string;
}

export const GameButton = (props: GameButtonProps) => {
  const { children, disabled, variant, onClick, style, className, size } = props;
  return (
    <div
      className={`game-button_root game-button_${variant} ${size === 'sm' ? 'game-button_root-sm' : ''} ${
        disabled === true ? 'game-button_disabled' : 'game-button_enabled'
      } ${className ?? ''}`}
      onClick={disabled === true ? undefined : onClick}
      style={style}
    >
      <div className={'game-button_child-container'}>{children}</div>
    </div>
  );
};
