import React from 'react';
import './BrushesPopover.css';
import { objectKeys } from '@magicyard/doodledash-shared/utils';

enum BrushTypes {
  xl = 12,
  large = 9.4,
  medium = 6.8,
  small = 4.2,
}

interface BrushesPopoverProps {
  onClick: (size: number | undefined) => void;
  color: string;
}

export const BrushesPopover = (props: BrushesPopoverProps) => {
  const { onClick } = props;
  const brushesIcons = objectKeys(BrushTypes).map((k, i) => (
    <span
      key={k}
      onClick={() => onClick(BrushTypes[k])}
      style={{
        height: `${BrushTypes[k]}px`,
        marginBottom: `${BrushTypes[k] + 10}px`,
        animationDelay: i * 0.05 + 0.05 + 's',
        background: props.color,
      }}
      className={'brush_line'}
    />
  ));
  return (
    <div className={'brush-popover_root'}>
      <div className={'brush-popover_icons-container'}>{brushesIcons}</div>
    </div>
  );
};
