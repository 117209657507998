import React, { useEffect, useMemo } from 'react';
import { useGameObject } from '../store/GameContext';
import { Phases } from '@magicyard/doodledash-game/src/Game';
import { ActiveDrawingSimultaneous } from './Stages/active-drawing-simultaneous/ActiveDrawingSimultaneous';
import Background from '../components/Background/Background';
import { NavigationBar } from '../components/navigation-bar/NavigationBar';
import LookAtTvRatLoader from '../assets/loaders/LookAtTvRatLoader.webp';
import { LoaderTemplate } from '../components/loader-template/LoaderTemplate';
import { PickTeam } from './Stages/PickTeam';
import { getActivePlayerId } from '@magicyard/doodledash-game/src/Types';
import { track } from '@magicyard/shared/src/analytics';
import { DriftDbSocketProvider } from '@magicyard/shared/src/DriftDbSocketContext';
import { WinnerScene } from './WinnerScene';

const LookAtScreen = () => {
  return <LoaderTemplate image={LookAtTvRatLoader} />;
};

const ScoringPhase = () => {
  useEffect(() => {
    track('Scoring Phases Started');
  }, []);
  return <LookAtScreen />;
};

const StageManager = (props: { onStartNewGame: () => void; onTryDifferentGames?: () => void }) => {
  const { ctx, G, playerID } = useGameObject();

  const stages: Record<Phases, () => JSX.Element> = useMemo(
    () => ({
      [Phases.Sync]: LookAtScreen,
      [Phases.PickTeam]: PickTeam,
      [Phases.Playing]: ActiveDrawingSimultaneous,
      [Phases.Scoring]: ScoringPhase,
      [Phases.GameEnd]: () => (
        <WinnerScene onStartNewGame={props.onStartNewGame} onTryDifferentGames={props.onTryDifferentGames} />
      ),
    }),
    []
  );
  const phase = ctx.phase as Phases;
  const Stage = stages[phase];
  const playerTeam = G.teamsForPlayers.red.indexOf(playerID) === -1 ? 'blue' : 'red';
  if (getActivePlayerId(G) !== playerID && phase === 'Playing') {
    return (
      <Background>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '5vh',
            textAlign: 'center',
          }}
        >
          {!G.isSmall && G.activePlayer.team !== playerTeam
            ? 'The other team is playing...'
            : `Guess ${G.players[+getActivePlayerId(G)].name}'s drawing!`}
        </div>
      </Background>
    );
  }
  return (
    <Background>
      <NavigationBar header={''} />
      <DriftDbSocketProvider socketUrl={G.drawingSocketUrl}>
        <Stage />
      </DriftDbSocketProvider>
    </Background>
  );
};

export default StageManager;
