import type { BoardProps } from 'boardgame.io/react';
import MovesUtil from './utils/moves.util';
import { IPlayer, GameState, BoardPropTypes, CtxWithApi } from './Types';
import { Game, PhaseConfig, PlayerID } from 'boardgame.io';
import { ActivePlayers } from 'boardgame.io/core';
import movesUtil from './utils/moves.util';
import { getWordsForTopicForRound } from './words';

export const SMALL_ROUNDS_PER_PLAYER = 2;
export const POINTS_LOST_PER_SHUFFLE = 10;

export enum Phases {
  Sync = 'Sync',
  PickTeam = 'PickTeam',
  Playing = 'Playing',
  Scoring = 'Scoring',
  GameEnd = 'gameEnd',
}

interface SetupData {
  drawingSocketUrl: string;
  players?: IPlayer[];
  serverWords: ServerWord[];
}

export interface ServerWord {
  word: string;
  hint: string;
  difficulty: number;
}

export type G = GameState;

type BoardPropsExtended = {
  moves: { [key in keyof typeof MovesUtil]: (...arg: any) => void };
  ctx: CtxWithApi;
  playerID: PlayerID;
} & BoardProps<BoardPropTypes>;
export type GameObject = BoardPropsExtended;

const setup = (ctx: CtxWithApi, setupData?: SetupData): GameState => {
  // Weird server bug that only happens first time on local
  if (setupData === undefined) {
    return {} as any;
  }
  const defaultPlayers = Array(ctx.numPlayers - 1);

  const initPlayers = setupData.players || defaultPlayers.fill(null);

  const players: IPlayer[] = initPlayers.map((player, index) => {
    return {
      id: index.toString(),
      name: player?.name || `Player ${index}`,
      avatarUrl: player?.avatarUrl || `https://i.pravatar.cc/300?img=${index + 5}`,
      active: true,
    };
  });

  const initialTeams = players.reduce<{ red: PlayerID[]; blue: PlayerID[] }>(
    (acc, p, i) => {
      if (i % 2 === 0) {
        acc.red.push(p.id);
      } else {
        acc.blue.push(p.id);
      }
      return acc;
    },
    { red: [], blue: [] }
  );

  // Game State
  return {
    currentDrawingWord: null,
    isSmall: players.length < 4,
    smallMode: {
      activePlayerIndex: 0,
      bonusRoundCount: 0,
    },
    drawingSocketUrl: setupData.drawingSocketUrl,
    activePlayer: { team: 'red', red: 0, blue: 0 },
    didGameEnd: false,
    isTransition: true,
    phaseStartTime: Date.now(),
    players,
    shouldForceSubmit: false,
    teamsForPlayers: initialTeams,
    wordSelectionForRound: getWordsForTopicForRound(setupData.serverWords, []),

    // Gets initialized again after picking teams
    successfulGuessesForRound: [{ guesses: [], id: 0 }],
    refreshCountThisRound: 0,
    allWords: setupData.serverWords,
  };
};

export const getActiveGuessesAndPlayer = (G: GameState) =>
  G.successfulGuessesForRound[G.successfulGuessesForRound.length - 1];

const basePhase = {
  turn: {
    activePlayers: ActivePlayers.ALL,
  },
  moves: {
    timesUp: movesUtil.timesUp,
    forceEndPhase: movesUtil.forceEndPhase,
    transitionTimeUp: movesUtil.transitionTimeUp,
  },
  onEnd: (G: G) => {
    G.isTransition = true;
    G.shouldForceSubmit = false;
    G.phaseStartTime = Date.now();
  },
};

export const calcAmountOfRounds = (G: GameState) => {
  if (G.isSmall) {
    return G.players.length * SMALL_ROUNDS_PER_PLAYER;
  }
  const blueL = G.teamsForPlayers.blue.length;
  const redL = G.teamsForPlayers.red.length;
  const max = Math.max(blueL, redL);

  return max * 2;
};

const AllPhases: Record<Phases, PhaseConfig<G, CtxWithApi>> = {
  [Phases.Sync]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      endSync: MovesUtil.endSync,
    },
    next: (G) => {
      if (!G.isSmall) {
        return Phases.PickTeam;
      } else {
        return Phases.Playing;
      }
    },
    start: true,
  },
  [Phases.PickTeam]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      toggleTeam: MovesUtil.toggleTeam,
      confirmTeams: (G, ctx, forceStart) => {
        if (forceStart || (G.teamsForPlayers.red.length >= 2 && G.teamsForPlayers.blue.length >= 2)) {
          G.successfulGuessesForRound[0] = { guesses: [], id: G.teamsForPlayers['red'][0] };
          ctx.events.endPhase();
        }
      },
    },
    next: Phases.Playing,
  },
  [Phases.Playing]: {
    ...basePhase,
    next: (G) => {
      if (G.successfulGuessesForRound.length - 1 === calcAmountOfRounds(G) && G.isSmall) {
        return Phases.GameEnd;
      }
      return Phases.Scoring;
    },
    onEnd: (G, ctx) => {
      basePhase.onEnd(G);
      G.currentDrawingWord = null;
    },
    moves: {
      ...basePhase.moves,
      finishDrawing: movesUtil.finishDrawing,
      s00_confirmCycleCardWord: movesUtil.s00_confirmCycleCardWord,
      refreshWords: movesUtil.refreshWords,
    },
  },
  [Phases.Scoring]: {
    ...basePhase,
    next: (G) => {
      if (G.successfulGuessesForRound.length - 1 === calcAmountOfRounds(G)) {
        return Phases.GameEnd;
      }
      return Phases.Playing;
    },
    moves: {
      ...basePhase.moves,
      finishScoringEarlyIfNeeded: movesUtil.finishScoringEarlyIfNeeded,
    },
  },
  [Phases.GameEnd]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
    },
  },
};

export const GAME_ID = 'doodledash';

export const DoodleDash: Game<G, CtxWithApi> = {
  name: GAME_ID,
  setup: setup,
  moves: MovesUtil,
  phases: AllPhases,
};
