import React, { CSSProperties } from 'react';
import './SingleCanvas.css';

interface SingleCanvasProps {
  children: React.ReactNode;
  isView?: boolean;
  playerID: string;
  style?: CSSProperties;
}

export const SingleCanvas = (props: SingleCanvasProps) => {
  const { children, isView } = props;
  return (
    <div
      className={!isView ? 'single-canvas_root' : 'single-canvas_view-root'}
      style={{
        background: '#fff8d4',
        borderRadius: 10,
        border: '1px dashed #324663',
        ...props.style,
      }}
    >
      {children}
    </div>
  );
};
