import React from 'react';
import { Point } from '@magicyard/doodledash-game/src/Types';

/**
 * convert event to point
 */
export const toPoint = (event: React.MouseEvent): Point => {
  const { nativeEvent } = event;

  const x: number = nativeEvent.offsetX;
  const y: number = nativeEvent.offsetY;

  return { x, y };
};

interface Offsets {
  x: number;
  y: number;
}

export const touchToPoint = (event: React.TouchEvent, offsets: Offsets = { x: 0, y: 0 }): Point => {
  const x: number = event.touches[0].clientX - offsets.x;
  const y: number = event.touches[0].clientY - offsets.y;

  return { x, y };
};
