import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { touchToPoint } from '../utils';
import { Point } from '@magicyard/doodledash-game/src/Types';
import MovesUtil from '@magicyard/doodledash-game/src/utils/moves.util';
import './DrawingBoard.css';
import BinIcon from '../../../assets/doodleDash/trash.webp';
import { track } from '@magicyard/shared/src/analytics';
import { getStorageKeyForPlayer } from '../../../GameManager/Stages/active-drawing-simultaneous/ActiveDrawingSimultaneous';
import { useDriftDbSocketContext } from '@magicyard/shared/src/DriftDbSocketContext';

interface DrawingBoardProps {
  currentColor: string;
  currentWidth: number;
  moves: { [key in keyof typeof MovesUtil]: (...args: any) => void };
  storageKey: string;
  yardId: string;
  mode: 'draw' | 'erase';
  playerId: string;
  onTouchEnd: () => void;
}

interface Data {
  lastPoint: Point;
  force: number;
  to: Point;
  color: string;
}

const useOnWindowResize = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [callback]);
};

export const DrawingBoard = ({ onTouchEnd, currentColor, currentWidth, yardId, mode, playerId }: DrawingBoardProps) => {
  const socket = useDriftDbSocketContext();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const originPoint = useRef<Point | null>(null);
  const lastPoint = useRef<Point | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);

  const [isShowClearPopup, setIsShowClearPopup] = useState(false);
  const handleClear = () => {
    ctxRef.current!.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    socket.send({
      key: 'broadcast',
      type: 'push',
      action: { type: 'relay' },
      value: {
        event: 'clear',
        data: { lastPoint: lastPoint.current, to: { x: 0, y: 0 }, force: currentWidth, color: currentColor },
      },
    });

    localStorage.setItem(getStorageKeyForPlayer(playerId), '');

    track('Sketch Delete Clicked');
  };

  useEffect(() => {
    return () => {
      localStorage.setItem(getStorageKeyForPlayer(playerId), '');
    };
  }, []);

  const resizeCanvas = useCallback(() => {
    const parent = canvasRef.current!.parentElement!;
    canvasRef.current!.width = parent.clientWidth;
    canvasRef.current!.height = parent.clientHeight;
    const img = new Image();
    img.onload = function () {
      requestAnimationFrame(() => {
        ctxRef.current!.drawImage(img, 0, 0);
      });
    };
    img.src = localStorage.getItem(getStorageKeyForPlayer(playerId)) ?? '';
  }, []);

  useOnWindowResize(resizeCanvas);

  useEffect(() => {
    ctxRef.current = canvasRef.current!.getContext('2d');
    resizeCanvas();
  }, []);

  const onUp = () => {
    if (originPoint.current !== null && mode === 'draw' && lastPoint.current === null) {
      draw({
        lastPoint: originPoint.current,
        to: originPoint.current,
        force: currentWidth,
        color: currentColor,
      });
    }
    onTouchEnd();
    localStorage.setItem(getStorageKeyForPlayer(playerId), canvasRef.current!.toDataURL('image/png', 0.8));
    lastPoint.current = null;
    originPoint.current = null;
  };

  const onDown = (e: React.TouchEvent<HTMLCanvasElement>) => {
    const { x, y, width, height } = canvasRef.current!.getBoundingClientRect();
    originPoint.current = touchToPoint(e, { x, y });
  };

  function draw(data: Data) {
    ctxRef.current!.beginPath();
    ctxRef.current!.moveTo(data.lastPoint.x, data.lastPoint.y);
    ctxRef.current!.lineTo(data.to.x, data.to.y);
    ctxRef.current!.strokeStyle = data.color;
    ctxRef.current!.lineWidth = data.force;
    ctxRef.current!.lineCap = 'round';
    ctxRef.current!.stroke();
    ctxRef.current!.closePath();

    socket.send({
      key: 'broadcast',
      type: 'push',
      action: { type: 'relay' },
      value: {
        event: 'draw',
        data: data,
      },
    });
  }

  function erase(data: Data) {
    ctxRef.current!.clearRect(
      data.lastPoint.x - data.force / 2,
      data.lastPoint.y - data.force / 2,
      data.force,
      data.force
    );

    socket.send({
      key: 'broadcast',
      type: 'push',
      action: { type: 'relay' },
      value: {
        event: 'erase',
        data: data,
      },
    });
  }

  const move = (e: React.TouchEvent<HTMLCanvasElement>) => {
    const { x, y, width, height } = canvasRef.current!.getBoundingClientRect();
    const point = touchToPoint(e, { x, y });
    if (e.touches.length > 0) {
      if (lastPoint.current === null) {
        lastPoint.current = { x: point.x, y: point.y };
        originPoint.current = { x: point.x, y: point.y };
        return;
      }
      if (mode === 'draw') {
        draw({
          lastPoint: lastPoint.current,
          to: point,
          force: currentWidth,
          color: currentColor,
        });
      }
      if (mode === 'erase') {
        const force = currentWidth * 8;
        erase({
          lastPoint: lastPoint.current,
          to: point,
          force: force,
          color: currentColor,
        });
      }

      lastPoint.current = { x: point.x, y: point.y };
    } else {
      lastPoint.current = null;
    }
  };

  return (
    <>
      <img
        src={BinIcon}
        onClick={() => setIsShowClearPopup(true)}
        className={'sketch_bin-icon sketch_bin-icon-position'}
      />
      {isShowClearPopup && (
        <div className={'sketch_bin-popup-container'} onClick={() => setIsShowClearPopup(false)}>
          <div className={'sketch_bin-popup sketch_bin-icon-position'} onClick={handleClear}>
            <div className={'sketch_bin-icon-open'} />
            <div className={'sketch_red-bg'}>
              <div className={'sketch_red-bg-text'}>Yes</div>
            </div>
          </div>
        </div>
      )}
      <canvas
        ref={canvasRef}
        onTouchStart={onDown}
        onTouchMove={move}
        onTouchEnd={onUp}
        className={'drawing-board_root'}
      ></canvas>
    </>
  );
};
